import PropTypes from 'prop-types';
import queryString from 'query-string';
import lfHoc from '@holmanfm/lib/lf-hoc/lf-hoc';
import { logOut } from '@holmanfm/lib/redux/actions/auth.actions';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import { safeGet } from '@holmanfm/lib/util/helpers';
import sharedContainer from '@holmanfm/lib/activity-containers/logout';
import { RouterProps } from '@holmanfm/lib/common/app-prop-types';
import webContainer from './logout.container';

/**
 * This component dispatches the Logout action to the redux state and redirects the user
 * to the correct path on the site for their organization. The flow works as follows:
 * 1. Expect the component to be mounted as the component for a route.
 * 2. Always render this component every time the router must choose a route, and render it first.
 * 3. In this, componentDidUpdate must latch on a state change of `loggingOut` and dispatch the
 *   actual LOGOUT action during that transition.
 * 4. The LOGOUT action must clear the entire state (including `loggingOut`).
 */
const Logout = () => null;

Logout.propTypes = {
  history: RouterProps.history.isRequired,
  location: RouterProps.location.isRequired,
  loggingOut: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string.isRequired,
};

Logout.defaultProps = {};

export const getRedirectUrl = (location, authUrl, explicitLogout) => {
  if (location.pathname.includes('/login')) {
    return location.pathname + location.search;
  }
  // support external links - use correct login page
  if (!authUrl) {
    ({ authurl: authUrl } = queryString.parse(location.search));
  }

  const path = authUrl ? `/login/${authUrl}` : '/';

  let query;
  if (explicitLogout) {
    // for explicit logout, forget query.
    query = '';
  } else if (location.search.includes('back=')) {
    // Preserve back query when when it's an external link or logged out user refreshes the page.
    query = location.search;
  } else {
    // If user was logged out automatically, preserve last known location.
    const back = location.pathname + location.search;
    query = `?back=${encodeURIComponent(back)}`;
  }
  return path + query;
};

const mapStateToProps = (state, { location }) => {
  const org = selectors.getOrg(state);
  const { loggingOut, explicitLogout } = selectors.getLoggingOut(state);
  const authUrl =
    safeGet(org, ['auth_url']) || safeGet(org, ['parent', 'auth_url']);
  return {
    redirectUrl: getRedirectUrl(location, authUrl, explicitLogout),
    loggingOut,
  };
};

const lfContainer = lfHoc(mapStateToProps, {
  onLogout: logOut,
});

export default lfContainer(webContainer(sharedContainer(Logout)));
