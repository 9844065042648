import { useCallback } from 'react';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

export function successToastFactory() {
  return (message, options) =>
    enqueueSnackbar(message, { variant: 'success', ...options });
}

export function infoToastFactory() {
  return (message, options) =>
    enqueueSnackbar(message, { variant: 'info', ...options });
}

export function warningToastFactory() {
  return (message, options) =>
    enqueueSnackbar(message, { variant: 'warning', ...options });
}

export function errorToastFactory() {
  return (message, options) =>
    enqueueSnackbar(message, { variant: 'error', ...options });
}

export const useToasts = () => {
  const success = useCallback(successToastFactory(), [enqueueSnackbar]);
  const error = useCallback(errorToastFactory(), [enqueueSnackbar]);
  const warning = useCallback(warningToastFactory(), [enqueueSnackbar]);
  const info = useCallback(infoToastFactory(), [enqueueSnackbar]);
  const close = useCallback(closeSnackbar, [closeSnackbar]);

  return {
    success,
    error,
    warning,
    info,
    close,
  };
};
