import * as React from 'react';
import PropTypes from 'prop-types';
import MUIRadio from '@mui/material/Radio';

const Radio = ({ color, ...rest }) => {
  return <MUIRadio color={color} {...rest} />;
};

Radio.propTypes = {
  color: PropTypes.string,
};
Radio.defaultProps = {
  color: 'secondary',
};
export default Radio;
