import React from 'react';
import PropTypes from 'prop-types';

const withLogoutHandler = WrappedComponent => {
  const WithLogoutHandler = props => {
    const { loggingOut, onLogout, redirectUrl, redirect } = props;

    React.useEffect(() => {
      if (loggingOut) {
        onLogout().then(() => redirect(redirectUrl));
      }
    }, [loggingOut, onLogout, redirect, redirectUrl]);

    return <WrappedComponent {...props} />;
  };

  WithLogoutHandler.propTypes = {
    loggingOut: PropTypes.bool.isRequired,
    onLogout: PropTypes.func.isRequired,
    redirectUrl: PropTypes.string.isRequired,
    redirect: PropTypes.func.isRequired,
  };

  return WithLogoutHandler;
};

export default withLogoutHandler;
