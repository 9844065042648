import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import {
  NavLink as Link,
  useLocation,
  matchPath,
  NavLink as RouterNavLink,
} from 'react-router-dom';
import { useTranslation } from '@holmanfm/lib/lang';

import { ENV } from '@holmanfm/lib/common/environment';
import ListItemButton from '~/shared/components/list-item-button';
import ListItemText from '~/shared/components/list-item-text';
import Menu from '~/shared/components/menu';
import Typography from '~/shared/components/atom/typography';
import makeStyles from '~/shared/components/makeStyles';
import useMediaQuery from '~/shared/components/useMediaQuery';

const useStyles = makeStyles(theme => ({
  linkStyle: {
    fontSize: 14,
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
    },
  },
  linkWrapper: {
    margin: theme.spacing(2),
    flex: 1,
    '& div': {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      fontSize: 14,
    },
    '& li': {
      [theme.breakpoints.up('lg')]: {
        width: 190,
      },
      marginBottom: theme.spacing(1),
    },
  },
  linkWrapperWidth: {
    margin: theme.spacing(2),
    flex: 1,
    maxWidth: 188,
    '& a': {
      fontWeight: 'bold',
      marginBottom: 14,
      display: 'block',
      fontSize: 14,
    },
    '& p': {
      fontSize: 14,
    },
  },
  topLevelLink: {
    color: '#0069A7',
    borderBottom: '3px solid',
  },
  requestsLabelWrapper: {
    whiteSpace: 'nowrap',
    marginRight: '0.5em',
  },
}));

const ForwardNavLink = React.forwardRef((props, ref) => (
  <RouterNavLink {...props} ref={ref} />
));

const isTest = ENV === 'test';

const hasSubRoutes = route => {
  return route.subroutes && route.subroutes.length > 0;
};

const getPosition = topLevel => {
  if (topLevel) {
    return { vertical: 'bottom', horizontal: 'left' };
  }

  return { vertical: 'top', horizontal: 'right' };
};

const getLink = route => {
  return (
    route.navigation.fullTo || route.routing?.fullPath || route.navigation.to
  );
};

export const StyledSubmenu = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { route, parentHandleClose } = props;
  const { id, subroutes } = route;
  const classes = useStyles();

  if (!hasSubRoutes(route)) {
    return (
      <div
        ref={ref}
        data-testid={`menu-button-${id}`}
        className={classes.linkWrapperWidth}
      >
        <Link
          className={classes.linkStyle}
          onClick={parentHandleClose}
          to={getLink(route)}
        >
          {t(`main:nav:primary.${id}`)}
        </Link>
        <Typography variant="body2">{route?.details}</Typography>
      </div>
    );
  }

  return (
    <div data-testid="link-header" className={classes.linkWrapper}>
      <div>{t(`main:nav:primary.${id}`)}</div>
      {subroutes?.map(sr => (
        <li key={sr.id}>
          <Link
            onClick={parentHandleClose}
            className={classes.linkStyle}
            to={getLink(sr)}
          >
            {t(`main:nav:primary.${sr.id}`)}
          </Link>
        </li>
      ))}
    </div>
  );
});

StyledSubmenu.propTypes = {
  route: PropTypes.shape({
    id: PropTypes.string,
    subroutes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        navigation: PropTypes.shape({}),
      })
    ),
    navigation: PropTypes.shape({
      to: PropTypes.string,
      fullTo: PropTypes.string,
    }),
    routing: PropTypes.shape({
      fullPath: PropTypes.string,
    }),
  }).isRequired,
  parentHandleClose: PropTypes.func.isRequired,
};

// @TODO this name is a little bit misleading now
export const NavLink = React.forwardRef((props, ref) => {
  const { route, topLevel, className } = props;
  const { id, navigation } = route;
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const { t } = useTranslation();
  const classes = useStyles();

  const location = useLocation();

  const [anchorElement, setAnchorElement] = React.useState(null);
  const open = Boolean(anchorElement);

  const handleOpen = event => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = (_, reason) => {
    const { parentHandleClose } = props;
    setAnchorElement(null);
    if (parentHandleClose && !reason) {
      parentHandleClose();
    }
  };

  const isActive = Boolean(
    matchPath(location.pathname, { path: navigation.to })
  );

  /** The top-navigation menu button (you click this and a menu opens a menu below) */
  const RenderListItem = (
    <ListItemButton
      component="li"
      data-testid={`top-level-menu-button-${id}`}
      onClick={handleOpen}
      selected={isActive}
      className={classnames({ [classes.topLevelLink]: open })}
    >
      <ListItemText
        primaryTypographyProps={{ className: 'list-item-nav' }}
        primary={t(`main:nav:primary.${id}`)}
      />
    </ListItemButton>
  );

  return (
    <>
      {navigation?.isTopLink ? (
        <ListItemButton
          data-testid={`top-level-menu-button-${id}`}
          component={ForwardNavLink}
          to={route?.routing?.path}
        >
          <Typography className={classes.requestsLabelWrapper}>
            {t(`main:nav:primary.${id}`)}
          </Typography>
        </ListItemButton>
      ) : (
        <>
          {RenderListItem}
          <Menu
            keepMounted={isTest}
            data-testid={`navlink-menu-${id}`}
            anchorEl={anchorElement}
            anchorOrigin={getPosition(topLevel)}
            transitionDuration={200}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              style: { display: 'flex', flexDirection: isMobile && 'column' },
            }}
            PaperProps={{ style: { borderRadius: 0 } }}
          >
            {route.subroutes.map(subroute => (
              <StyledSubmenu
                route={subroute}
                key={subroute.id}
                ref={ref}
                parentHandleClose={handleClose}
                className={className}
              />
            ))}
          </Menu>
        </>
      )}
    </>
  );
});

NavLink.propTypes = {
  route: PropTypes.shape().isRequired,
  topLevel: PropTypes.bool,
  className: PropTypes.string,
  parentHandleClose: PropTypes.func,
};

NavLink.defaultProps = {
  topLevel: false,
  className: '',
  parentHandleClose: null,
};

export default NavLink;
