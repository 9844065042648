import { useEffect, useState } from 'react';
import useBrowserInfo from '~/shared/components/useBrowserInfo';

export const VIEW_STORAGE_KEY = 'team';

export const URL_PARAM = 'team';

export const VIEW_STORAGE_TYPES = {
  STOCKTEAM: 'stock',
  DEFAULT: 'default',
};

/**
 * Adds a key value to localstorage if query param is provided
 * We want F&M's Stock ordering team to be able to navigate
 * Shop Stock Vehicles to help locate a vehicle for our customers
 * without accidentally triggering the order process through
 * HSBS/Dynamics so that we don't create an ordering conflict/issue.
 *
 * @returns {string|null} The 'viewType' parameter from the URL, or null if it doesn't exist.
 *
 * @example
 * import useViewType from './useViewType';
 *
 * const MyComponent = () => {
 *   useViewType();
 *   // rest of your component logic
 * };
 */

const useViewType = () => {
  const { params } = useBrowserInfo();
  const urlViewType = params[URL_PARAM];
  const [viewType, setViewType] = useState(() => {
    return localStorage.getItem(VIEW_STORAGE_KEY);
  });

  useEffect(() => {
    if (urlViewType === VIEW_STORAGE_TYPES.STOCKTEAM) {
      localStorage.setItem(VIEW_STORAGE_KEY, urlViewType);
      setViewType(urlViewType);
    } else if (urlViewType === VIEW_STORAGE_TYPES.DEFAULT) {
      localStorage.removeItem(VIEW_STORAGE_KEY);
      setViewType(null);
    }
  }, [urlViewType]);

  return viewType;
};

export default useViewType;
