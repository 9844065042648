import PropTypes from 'prop-types';
import React from 'react';
import IdleTimer from 'react-idle-timer';

import lfHoc from '@holmanfm/lib/lf-hoc/lf-hoc';
import { preLogOut } from '@holmanfm/lib/redux/actions/auth.actions';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import {
  LOGOUT_COUNTDOWN_SECONDS,
  IDLE_TIME_MINUTES,
} from '@holmanfm/lib/common/environment';
import Button from '~/shared/components/atom/button';
import Typography from '~/shared/components/atom/typography';
import Modal from '~/shared/components/modal';
import Paper from '~/shared/components/paper';
import withStyles from '~/shared/components/withStyles';

function format(time) {
  let seconds = time % 60;
  let minutes = Math.floor(time / 60);
  minutes = minutes.toString().length === 1 ? `0${minutes}` : minutes;
  seconds = seconds.toString().length === 1 ? `0${seconds}` : seconds;
  return `${minutes}:${seconds}`;
}

export class IdleTimerLogout extends React.Component {
  constructor(props) {
    super(props);
    this.logCountdownSeconds = Number(LOGOUT_COUNTDOWN_SECONDS);
    this.state = { idleModalShown: false, count: this.logCountdownSeconds };
  }

  onIdle = () => {
    const { loggedIn, onPreLogout } = this.props;
    if (!loggedIn) return;
    this.setState({
      idleModalShown: true,
    });

    this.timer = setInterval(() => {
      const { count } = this.state;
      const newCount = count - 1;
      if (newCount > 0) {
        this.setState({ count: newCount >= 0 ? newCount : 0 });
      } else {
        this.setState({ count: 0 });
        this.clearTimer();
        onPreLogout();
      }
    }, 1000);
  };

  clearTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.setState({ idleModalShown: false, count: this.logCountdownSeconds });
    }
  };

  onClose = () => {
    this.setState({ idleModalShown: false });
    this.clearTimer();
  };

  render() {
    const { classes, t } = this.props;
    const { idleModalShown, count } = this.state;

    return (
      <div>
        <Modal open={idleModalShown} onClose={this.onClose}>
          <Paper className={classes.paper}>
            <Typography variant="subtitle1" id="modal-title">
              {t('main:autoLogout:inactive-message', {
                idleTime: Number(IDLE_TIME_MINUTES),
                logoutTime: this.logCountdownSeconds / 60,
              })}
            </Typography>
            <Typography variant="h6" className={classes.pad}>
              {format(count)}
            </Typography>
            <div>
              <Button variant="contained" onClick={this.onClose}>
                {t('main:autoLogout:stay-signed-in')}
              </Button>
            </div>
          </Paper>
        </Modal>
        {IDLE_TIME_MINUTES && (
          <IdleTimer
            element={document}
            onIdle={this.onIdle}
            debounce={250}
            timeout={1000 * 60 * IDLE_TIME_MINUTES}
          />
        )}
      </div>
    );
  }
}

IdleTimerLogout.propTypes = {
  classes: PropTypes.shape({
    paper: PropTypes.string.isRequired,
    pad: PropTypes.string.isRequired,
  }).isRequired,
  loggedIn: PropTypes.bool.isRequired,
  onPreLogout: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const styles = theme => {
  return {
    paper: {
      top: '30%',
      left: '50%',
      transform: 'translate(-50%, -30%)',
      position: 'absolute',
      width: theme.spacing(60),
      padding: theme.spacing(4),
    },
    pad: {
      padding: theme.spacing(1),
    },
  };
};

const mapStateToProps = state => ({
  loggedIn: selectors.getLoggedIn(state),
});

const lfContainer = lfHoc(mapStateToProps, {
  onPreLogout: preLogOut,
});

export default lfContainer(withStyles(styles)(IdleTimerLogout));
