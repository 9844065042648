import { useSelector } from 'react-redux';
import { selectors } from '../redux/reducers/index.reducer';
import { isEmptyObject } from '../util/helpers';

/**
  * checks if current user is allowed to access a feature
  * ex: canAccessFeature(Features.SHOP_VEHICLES.INDEX)
  @param {string} feature - string describing a feature to be accessed
  @returns {(feature: string) => boolean}
*/
const canAccessFeature = ({ features }) => feature => {
  if (!features) return false;
  return features.findIndex(f => f === feature) >= 0;
};

/**
 * @deprecated
 */
const canAccessService = ({ org }) => serviceName => {
  if (!org || !serviceName) return false;
  return org.services.findIndex(service => service.name === serviceName) >= 0;
};

const canAccessFullMenu = org => {
  if (org) {
    let hasAccessToMenu = false;
    if (org.data && org.data.address && org.data.contacts) {
      hasAccessToMenu =
        !isEmptyObject(org.data.address) && !isEmptyObject(org.data.contacts);
    }
    return hasAccessToMenu;
  }
  return false;
};

const hasAssetBasedService = org => {
  if (!org?.services) {
    return false;
  }
  return org.services.some(service => service?.basis?.includes('asset'));
};

const usePermissions = () => {
  const features = useSelector(selectors.getFeatures);
  const org = useSelector(selectors.getOrg);
  const isOrgAdmin = useSelector(selectors.getIsOrgAdmin);

  return {
    canAccessFeature: canAccessFeature({ features }),
    canAccessFullMenu: canAccessFullMenu(org),
    hasAssetBasedService: hasAssetBasedService(org),
    generateProps: {
      features,
      isOrgAdmin,
      org,
      services: org?.services,
    },
    /**
     * @deprecated
     */
    canAccessService: canAccessService({ org }),
  };
};

export default usePermissions;
