import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink as Link } from 'react-router-dom';
import { ENV } from '@holmanfm/lib/common/environment';
import { useTranslation } from '@holmanfm/lib/lang';
import Typography from '~/shared/components/atom/typography';
import Menu from '~/shared/components/menu';
import ListItem from '~/shared/components/list-item';
import ListItemText from '~/shared/components/list-item-text';
import makeStyles from '~/shared/components/makeStyles';
import useMediaQuery from '~/shared/components/useMediaQuery';

const isTest = ENV === 'test';

const getPosition = { vertical: 'bottom', horizontal: 'left' };

const useStyles = makeStyles(theme => ({
  linkStyle: {
    fontSize: 14,
    color: 'black',
    '&:hover': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  },
  linkWrapper: {
    margin: theme.spacing(2),
    flex: 1,
    '& div': {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      fontSize: 14,
    },
    '& li': {
      [theme.breakpoints.up('lg')]: {
        width: 190,
      },
      marginBottom: theme.spacing(1),
    },
  },
  linkWrapperWidth: {
    margin: theme.spacing(2),
    flex: 1,
    maxWidth: 188,
    '& a': {
      fontWeight: 'bold',
      marginBottom: 14,
      display: 'block',
      fontSize: 14,
    },
    '& p': {
      fontSize: 14,
    },
  },
  topLevelLink: {
    color: '#0069A7',
    borderBottom: '3px solid',
  },
}));

export const StyledSubmenu = React.forwardRef((props, ref) => {
  const { parentHandleClose } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <div
        data-testid="menu-button-shop-stock"
        className={classes.linkWrapperWidth}
        ref={ref}
      >
        <Link
          className={classes.linkStyle}
          onClick={parentHandleClose}
          to="/marketplace/shop-stock-vehicles/landing"
        >
          {t('main:nav:primary.shop-stock-inventory')}
        </Link>
        <Typography variant="body2">
          View our dealer network of vehicles for your immediate or short-term
          needs
        </Typography>
      </div>
      <div
        data-testid="menu-button-shop-pool"
        className={classes.linkWrapperWidth}
        ref={ref}
      >
        <Link
          className={classes.linkStyle}
          onClick={parentHandleClose}
          to="/marketplace/pool-vehicle-acquisition"
        >
          {t('main:nav:primary.shop-pool-vehicles')}
        </Link>
        <Typography variant="body2">
          Source trade-specific vehicles pre-built for your business needs
        </Typography>
      </div>
      <div
        data-testid="menu-button-shop-factory"
        className={classes.linkWrapperWidth}
      >
        <Link
          className={classes.linkStyle}
          onClick={parentHandleClose}
          to="/marketplace/vehicle-acquisition"
        >
          {t('main:nav:primary.shop-factory-vehicles')}
        </Link>
        <Typography variant="body2">
          Plan future replacements with our lowest cost, standard vehicle
          offering
        </Typography>
      </div>
    </>
  );
});

StyledSubmenu.propTypes = {
  parentHandleClose: PropTypes.func.isRequired,
};

export const PublicNavLink = React.forwardRef((props, ref) => {
  const [anchorElement, setAnchorElement] = React.useState(null);
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const classes = useStyles();
  const open = Boolean(anchorElement);

  const handleOpen = event => {
    setAnchorElement(event.currentTarget);
  };

  const handleOnClose = (_, reason) => {
    const { parentHandleClose } = props;
    setAnchorElement(null);
    if (parentHandleClose && !reason) {
      parentHandleClose();
    }
  };

  return (
    <>
      <ListItem
        component="li"
        button
        onClick={handleOpen}
        className={classnames({ [classes.topLevelLink]: open })}
      >
        <ListItemText primary={t('main:nav:primary.shop-vehicles')} />
      </ListItem>
      <Menu
        keepMounted={isTest}
        anchorEl={anchorElement}
        anchorOrigin={getPosition}
        transitionDuration={200}
        open={Boolean(anchorElement)}
        onClose={handleOnClose}
        MenuListProps={{
          style: { display: 'flex', flexDirection: isMobile && 'column' },
        }}
      >
        <StyledSubmenu ref={ref} parentHandleClose={handleOnClose} />
      </Menu>
    </>
  );
});

PublicNavLink.propTypes = {
  parentHandleClose: PropTypes.func,
};

PublicNavLink.defaultProps = {
  parentHandleClose: null,
};

export default PublicNavLink;
