import axios from 'axios';
import ResponseMapper from './response-mapper';
import { safeGet } from './helpers';

export class NotAuthenticatedError extends Error {}

export class ErrorResponseHandler {
  // MAIN METHOD. returns report array.
  static call(err, ver) {
    if (!err || !ver) return this.defaultCustomError(err);

    if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return this.handleServerError(err, ver);
    }
    if (err.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return this.handleNoResponse(err);
    }
    // Something happened in setting up the request that triggered an Error
    console.log('Setting the Request Error:', err);
    return this.defaultCustomError(err);
  }

  static handleServerError(err, ver) {
    // Redirect. No Error
    if (Math.floor(err.response.status / 100) === 3) return [];

    if (err.response.data && !(typeof err.response.data === 'string')) {
      // if data is some object from server.
      return ResponseMapper.failure(err.response.data, ver);
    }
    return this.defaultCustomError(err);

    // if (err.response.status === 401) {
    //   // TODO think about how to handle it in components. We should dispatch logout
    //   throw new NotAuthenticatedError(); // perhaps add interceptor here?
    // } else if (err.response.status === 404) {
    //   return this.defaultCustomError(err);
    // } else if (Math.floor(err.response.status / 100) === 4 && err.response.data) {
    //
    //   return ResponseMapper.failure(err.response.data);
    //
    // } else {
    //   return this.defaultCustomError(err);
    // }
  }

  static handleNoResponse(err) {
    return this.defaultCustomError(err);
  }

  static defaultCustomError(err) {
    return [
      {
        code: 'untyped-error',
        type: 'error',
        message: `Try again later (status code ${(err &&
          err.response &&
          err.response.status) ||
          '0'})`,
      },
    ];
  }

  static createError(message) {
    return [
      {
        code: 'untyped-error',
        type: 'error',
        message,
      },
    ];
  }
}

class Api {
  constructor() {
    this.config = {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        'Content-Type': 'application/json',
      },
      baseURL: '',
      defaultVer: 'v1',
      // https://stackoverflow.com/questions/24687313/what-exactly-does-the-access-control-allow-credentials-header-do
      withCredentials: true,
    };

    this.axiosWrapper = this.axiosWrapper.bind(this);
    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
  }

  setBaseUrl(baseURL) {
    this.config = {
      ...this.config,
      baseURL,
    };
  }

  getCookieByName(cookie, name) {
    const match = cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    if (match) return match[2];
  }

  // For Mobile Only - only mobile can access cookie header
  checkCookie(response) {
    const cookie = safeGet(response, ['headers', 'set-cookie', 0]);
    if (this.onChangeTokens && cookie) {
      const tokens = {
        AccessToken: this.getCookieByName(cookie, 'AccessToken'),
        IdToken: this.getCookieByName(cookie, 'IdToken'),
        RefreshToken: this.getCookieByName(cookie, 'RefreshToken'),
      };
      this.setTokens(tokens);
      this.onChangeTokens(tokens);
    }
  }

  // NOTE url is not absolute. Ex: /about
  // If error occurs, it may throw string OR object
  async axiosWrapper(type, url, params, ver = this.config.defaultVer) {
    try {
      let prefix = '';
      if (ver) prefix = `/${ver}`;

      url = this.config.baseURL + prefix + url; // append base url
      const response = await axios[type](
        url,
        ['get', 'delete'].includes(type)
          ? { ...params, ...this.config }
          : params || this.config,
        this.config
      );
      this.checkCookie(response);
      return ResponseMapper.success(response.data, ver);
    } catch (err) {
      // if(err.response && err.response.status === 401)
      //   return Promise.reject(new NotAuthenticatedError())
      // return Promise.reject("Invalid Credentials")

      // return Promise.reject(err.response && JSON.stringify(err.response));

      // return Promise.reject(ResponseMapper.failure(err.response && err.response.data))
      return Promise.reject(ErrorResponseHandler.call(err, ver));
    }
  }

  async axiosUpload(type, url, name, data, ver = this.config.defaultVer) {
    try {
      const prefix = ver ? `/${ver}` : '';
      url = this.config.baseURL + prefix + url; // append base url
      const formData = new FormData();
      formData.append(name, data);
      const response = await axios[type](url, formData, {
        headers: {
          'Cache-Control': 'no-cache',
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      this.checkCookie(response);
      return ResponseMapper.success(response.data, ver);
    } catch (err) {
      return Promise.reject(ErrorResponseHandler.call(err, ver));
    }
  }

  // For Mobile Only - only mobile can access cookie header
  setOnChangeTokens(onChangeTokens) {
    this.onChangeTokens = onChangeTokens;
  }

  /**
    Sets authorization tokens that will be used for every request.
    @param tokens - { IdToken, AccessToken, RefreshToken }
  */
  setTokens(tokens) {
    this.config.Cookie = `AccessToken=${tokens.AccessToken}; IdToken=${tokens.IdToken}; RefreshToken=${tokens.RefreshToken}`;
  }

  removeTokens() {
    delete this.config.Cookie;
  }

  async get(url, params, ver = this.config.defaultVer) {
    const res = await this.axiosWrapper('get', url, params, ver);
    return res;
  }

  async post(url, params, ver = this.config.defaultVer) {
    const res = await this.axiosWrapper('post', url, params, ver);
    return res;
  }

  async delete(url, params, ver = this.config.defaultVer) {
    const res = await this.axiosWrapper('delete', url, params, ver);
    return res;
  }

  async put(url, params, ver = this.config.defaultVer) {
    const res = await this.axiosWrapper('put', url, params, ver);
    return res;
  }

  async patch(url, params, ver = this.config.defaultVer) {
    const res = await this.axiosWrapper('patch', url, params, ver);
    return res;
  }

  async getRaw(url, params, ver = this.config.defaultVer) {
    try {
      let prefix = '';
      if (ver) prefix = `/${ver}`;
      url = this.config.baseURL + prefix + url; // append base url
      const response = await axios.get(url, { ...params, ...this.config });
      return response.data;
    } catch (err) {
      return Promise.reject(ErrorResponseHandler.call(err, ver));
    }
  }
}

export default new Api();
