import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '@holmanfm/lib/lang';

import { useDispatch } from '@holmanfm/lib/redux';
import { preLogOut } from '@holmanfm/lib/redux/actions/auth.actions';
import IconButton from '~/shared/components/atom/icon-button';
import Divider from '~/shared/components/divider';
import Menu from '~/shared/components/menu';
import MenuItem from '~/shared/components/menu-item';
import Avatar from '~/shared/icons/avatar';

export const UserNavigationMenu = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const logout = () => dispatch(preLogOut(true));

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        className="browser-default"
        onClick={handleClick}
        data-testid="user-navigation-button"
      >
        <Avatar />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        data-testid="user-navigation-menu"
      >
        <MenuItem
          data-testid="my-account-link"
          component={Link}
          to="/my-account"
          onClick={handleClose}
        >
          {t('main:my-account:title')}
        </MenuItem>
        <Divider />
        <MenuItem data-testid="logout-button" onClick={logout}>
          {t('main:nav:secondary:logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserNavigationMenu;
