import '@babel/polyfill';
import React from 'react';
import {
  BASE_URL,
  ENABLE_REDUX_DEVTOOLS,
  ENABLE_REDUX_LOGGER,
  HEROKU_RELEASE_VERSION,
  HOST_ENV,
  isProduction,
  SENTRY_DSN,
} from '@holmanfm/lib/common/environment';
import {
  aceInterceptor,
  authInterceptor,
  langInterceptor,
} from '@holmanfm/lib/interceptors';
import '@holmanfm/lib/lang';
import { Provider, storeFactory } from '@holmanfm/lib/redux';
import StorageSystem from '@holmanfm/lib/storage/storage';
import API from '@holmanfm/lib/util/api';
import SentryProvider from '@holmanfm/lib/util/sentry-provider';
import { readyPromise } from '@holmanfm/lib/util/moment';
import authService from '@holmanfm/lib/util/auth.service';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import StyledEngineProvider from '~/shared/components/styled-engine-provider';
import LocalizationProvider from '~/shared/components/localization-provider';
import AdapterDayjs from '~/shared/components/adapterDayjs';
import CssBaseline from '~/shared/components/css-baseline';
import StripeProvider from '~/shared/components/stripe-provider';
import redirectInterceptor from '~/shared/interceptors/redirect.interceptor';
import RouteProvider from '~/hooks/routing/route-provider';
import Logout from './areas/logout';
import './index.css';
import Main from './main';
import PublicMain from './public-main';
import { isPublicApp } from './shared/environment';
import CustomThemeProvider from './shared/components/custom-theme-provider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  },
});

if (process.env.NODE_ENV === 'development') {
  whyDidYouRender(React);
}

API.setBaseUrl(BASE_URL);
const Storage = new StorageSystem(localStorage);

function registerInterceptors(store) {
  authInterceptor(store);
  aceInterceptor(store);
  redirectInterceptor();
  langInterceptor();
}

if (isProduction) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: HEROKU_RELEASE_VERSION,
    environment: HOST_ENV,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.25,
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      // if (event.exception) {
      //   Sentry.showReportDialog({ eventId: event.event_id });
      // }
      return event;
    },
  });
}
SentryProvider.setSentry(Sentry);

(async function load() {
  const [store] = await Promise.all([
    storeFactory(Storage, ENABLE_REDUX_LOGGER, ENABLE_REDUX_DEVTOOLS),
    readyPromise,
  ]);

  registerInterceptors(store);

  // If it's a public app, automatically log out.
  // Otherwise, there are some possible issues because website has access to the cookies from the main app
  if (isPublicApp) {
    try {
      await authService.logOut();
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  const topRoutes = isPublicApp ? (
    // Public app. No authentication
    <Route component={PublicMain} />
  ) : (
    // Normal app
    <>
      <Route component={Logout} />
      <Route component={Main} />
    </>
  );

  // When store ready, mount react.
  const container = document.getElementById('app');
  const root = createRoot(container);
  root.render(
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Provider store={store}>
          <StripeProvider>
            <StyledEngineProvider injectFirst>
              <CustomThemeProvider>
                <CssBaseline>
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <BrowserRouter>
                      <RouteProvider>{topRoutes}</RouteProvider>
                    </BrowserRouter>
                  </SnackbarProvider>
                </CssBaseline>
              </CustomThemeProvider>
            </StyledEngineProvider>
          </StripeProvider>
        </Provider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
})();
