import * as React from 'react';
import PropTypes from 'prop-types';
import MUISelect from '@mui/material/Select';

const Select = ({ size, ...rest }) => {
  return <MUISelect size={size} {...rest} />;
};

Select.propTypes = {
  size: PropTypes.string,
};
Select.defaultProps = {
  size: 'small',
};
export default Select;
