import pluralize from 'pluralize';
import { combineReducers } from 'redux'; // eslint-disable-line
import { createSelector } from 'reselect';
import { AuthActionTypes, OrgActionTypes } from '../actions/action-types';
import aceReducer from './ace.reducer';
import authReducer, * as fromAuth from './auth.reducer';
import cart, * as fromCart from './cart/cart.reducer';
import documents, * as fromDocuments from './documents.reducer';
// Entities
import {
  createEntityReducer,
  createEntitySelectors,
} from './entity/create-entity.reducer';
import { mapSelectors } from './entity/util';
import marketplace, * as fromMPShopVehicles from './marketplace/shop-vehicles/shop-vehicles.reducer';
import orgNodes, * as fromOrgNodes from './org-nodes/org-nodes.reducer';
import orgsReducer, * as fromOrgs from './orgs.reducer';
import paymentCustomer, * as fromPaymentCustomer from './paymentCustomer.reducer';
import settings, * as fromSettings from './settings.reducer';
import payments, * as fromStripe from './stripe.reducer';
import uiCache, * as fromUICache from './ui-cache/ui-cache.reducer';
import serviceRequestsReducer, * as fromServiceRequests from './service-requests/service-requests.reducer';

const ENTITIES = [
  { entity: 'asset', options: { replacePrev: true } },
  { entity: 'widget', options: {} },
  { entity: 'user', options: {} },
  { entity: 'geofenceType', options: {} },
  { entity: 'paymentHistory', options: {} },
  { entity: 'role', options: {} },
  { entity: 'permission', options: {} },
  { entity: 'paymentCard', options: {} },
  { entity: 'paymentAccount', options: {} },
  { entity: 'driverTraining', options: {} },
  { entity: 'service', options: {} },
  { entity: 'organizationLocation', options: {} },
];

const reducers = {
  ace: aceReducer,
  auth: authReducer,
  orgs: orgsReducer,
  serviceRequests: serviceRequestsReducer,
  orgNodes,
  settings,
  payments,
  documents,
  paymentCustomer,
  cart,
  uiCache,
  marketplace,
};

// Add entites.
ENTITIES.forEach(entity => {
  reducers[pluralize(entity.entity)] = createEntityReducer(
    entity.entity,
    entity.options
  );
});

const appReducer = combineReducers(reducers);

// Wrapper to add meta reducers (middleware).
const rootReducer = (state, action) => {
  // invalidate store on logout.
  if (action.type === AuthActionTypes.LOGOUT) {
    state = undefined;
  }

  if (action.type === OrgActionTypes.SELECT_ORG_SUCCESS) {
    state = { auth: state.auth, orgs: state.orgs }; // reload data, keep only org and  auth state.
  }

  // add more meta reducers here

  return appReducer(state, action);
};

export default rootReducer;

//-----------------------------------
// Selectors

// Entities auto generated selectors.

const hasBootstrapped = lastUpdated => lastUpdated > 0;

const selectors = {
  hasBootstrapped,
  ...ENTITIES.reduce((theSelectors, entity) => {
    theSelectors = {
      ...theSelectors,
      ...mapSelectors(
        createEntitySelectors(entity.entity),
        pluralize(entity.entity)
      ),
    };
    return theSelectors;
  }, {}),
  // Marketplace-Shop Vehicles
  getDeliveryOptions: state =>
    fromMPShopVehicles.getDeliveryOptions(state.marketplace),
  getPurchaseCheckoutOpen: state =>
    fromMPShopVehicles.getPurchaseCheckoutOpen(state.marketplace),
  getVehiclePurchaseAgreementAgreed: (state, selectorId) =>
    fromMPShopVehicles.getVehiclePurchaseAgreementAgreed(
      state.marketplace,
      selectorId
    ),
  getDeliveryAuthorizationReleaseAgreed: (state, selectorId) =>
    fromMPShopVehicles.getDeliveryAuthorizationReleaseAgreed(
      state.marketplace,
      selectorId
    ),

  // Auth
  getLoggingIn: state => fromAuth.getLoggingIn(state.auth),
  getLoadingAccount: state => fromAuth.getLoadingAccount(state.auth),
  getBootstrapStatus: state => fromAuth.getBootstrapStatus(state.auth),
  getLoggedIn: state => fromAuth.getLoggedIn(state.auth),
  getLoggingOut: state => fromAuth.getLoggingOut(state.auth),
  getHasLoggedOut: state => fromAuth.getHasLoggedOut(state.auth),
  getCurrentUser: state => fromAuth.getCurrentUser(state.auth),
  getAuthErrors: state => fromAuth.getAuthErrors(state.auth),
  getAccountStatus: state => fromAuth.getAccountStatus(state.auth),
  getAuthDetails: state => fromAuth.getAuthDetails(state.auth),
  getIsFetchingAuthDetails: state =>
    fromAuth.getIsFetchingAuthDetails(state.auth),
  getAuthDetailsErrors: state => fromAuth.getAuthDetailsErrors(state.auth),
  getServerDown: state => fromAuth.getServerDown(state.auth),

  // Org
  getIsFetchingOrg: state => fromOrgs.getIsFetchingOrg(state.orgs),
  getOrg: state => fromOrgs.getOrg(state.orgs),
  getOrgMessages: state => fromOrgs.getOrgMessages(state.orgs),
  getOrgs: state => fromOrgs.getOrgs(state.orgs),
  getFeatures: state =>
    fromOrgs.getOrg(state.orgs) && fromOrgs.getOrg(state.orgs).features,
  getOrgData: state =>
    fromOrgs.getOrg(state.orgs) && fromOrgs.getOrg(state.orgs).data,
  getOrgDataAddress: state => {
    const org = fromOrgs.getOrg(state.orgs);
    return org && org.data && org.data.address && org.data.address[0];
  },
  getOrgDataContact: state => {
    const org = fromOrgs.getOrg(state.orgs);
    return org && org.data && org.data.contacts && org.data.contacts[0];
  },
  getOrgDataInsurance: state => {
    const org = fromOrgs.getOrg(state.orgs);
    return org && org.data && org.data.insurance;
  },
  getOrgDataBilling: state => {
    const org = fromOrgs.getOrg(state.orgs);
    return org && org.data && org.data.billingInformation;
  },
  getOrgQuoteEmail: state => {
    const org = fromOrgs.getOrg(state.orgs);
    return org?.data?.quoteEmail;
  },
  getOrgDataFleetProfile: state => {
    const org = fromOrgs.getOrg(state.orgs);
    return org && org.data && org.data?.fleetProfile;
  },
  getOrgDataFactoryCodes: state => {
    const org = fromOrgs.getOrg(state.orgs);
    return org && org.data && org.data.oemFactoryOrderCodes;
  },
  getOrgDataLabels: state => {
    const org = fromOrgs.getOrg(state.orgs);
    return org && org.data && org.data.labels;
  },
  getOrgName: state => {
    const org = fromOrgs.getOrg(state.orgs);
    return org && org.data && org.data.organizationName;
  },
  getShortEnrolledServices: state => {
    const org = fromOrgs.getOrg(state.orgs);
    return org?.services || [];
  },
  getParentData: state => {
    const org = fromOrgs.getOrg(state.orgs);
    return org?.parent?.data;
  },
  getUserConfig: state => {
    const org = fromOrgs.getOrg(state.orgs);
    return org?.currentUserConfig;
  },
  getAssetAuxMetadata: state => {
    const org = fromOrgs.getOrg(state.orgs);
    return org?.config?.assetAuxMetadata;
  },
  getAssetDisplayFields: state => {
    /**
     * This logic covers the following flow:
     * 1. the user adds aux field metadata
     * 2. the user adds the aux field as a display field
     * 3. the user removes the aux field metadata
     * Since the aux field is still stored as a display field in db, we are performing in-place filtering to avoid issues
     */
    const displayFields = fromOrgs.getOrg(state.orgs)?.currentUserConfig?.asset
      ?.displayFields;
    const assetAuxMetadata = fromOrgs.getOrg(state.orgs)?.config
      ?.assetAuxMetadata;
    return displayFields?.filter(
      field =>
        !assetAuxMetadata ||
        !field.field?.startsWith('auxData') ||
        Boolean(assetAuxMetadata[field?.field])
    );
  },
  getFuelTransactionsDisplayFields: state => {
    /**
     * This logic covers the following flow (follows getAssetDisplayFields logic):
     * 1. the user adds aux field metadata
     * 2. the user adds the aux field as a display field
     * 3. the user removes the aux field metadata
     * Since the aux field is still stored as a display field in db, we are performing in-place filtering to avoid issues
     */
    const displayFields = fromOrgs.getOrg(state.orgs)?.currentUserConfig
      ?.fuelTransactions?.displayFields;
    const assetAuxMetadata = fromOrgs.getOrg(state.orgs)?.config
      ?.assetAuxMetadata;
    return displayFields?.filter(
      field =>
        !assetAuxMetadata ||
        !field.field?.startsWith('auxData') ||
        Boolean(assetAuxMetadata[field?.field])
    );
  },

  // Org Nodes
  getIsFetchingOrgNodes: state =>
    fromOrgNodes.getIsFetchingOrgNodes(state.orgNodes),
  getOrgNodesMessages: state =>
    fromOrgNodes.getOrgNodesMessages(state.orgNodes),
  getOrgNodes: state => fromOrgNodes.getOrgNodes(state.orgNodes),
  getOrgNodesDict: state => fromOrgNodes.getOrgNodesDict(state.orgNodes),
  getRootOrgNodes: state => fromOrgNodes.getRootOrgNodes(state.orgNodes),
  getOrgNodesForLevel: (state, level) =>
    fromOrgNodes.getOrgNodesForLevel(state.orgNodes, level),
  getOrgNodesTree: state => fromOrgNodes.getOrgNodesTree(state.orgNodes),
  getOrgNodeChildren: (state, id) =>
    fromOrgNodes.getOrgNodeChildren(state.orgNodes, id),

  getOrgNode: (state, id) => fromOrgNodes.getOrgNode(state.orgNodes, id),
  getIsFetchingOrgNode: (state, id) =>
    fromOrgNodes.getIsFetchingOrgNode(state.orgNodes, id),
  getOrgNodeMessages: (state, id) =>
    fromOrgNodes.getOrgNodeMessages(state.orgNodes, id),

  // Stripe
  getPayment: state => fromStripe.getStripePayments(state.payments),
  getIsFetchingPayment: state =>
    fromStripe.getIsFetchingStripePayments(state.payments),
  getPaymentMessages: state =>
    fromStripe.getStripePaymentMessages(state.payments),

  /** PaymentCustomer */
  getPaymentCustomer: state =>
    fromPaymentCustomer.getPaymentCustomer(state.paymentCustomer),
  getIsFetchingPaymentCustomer: state =>
    fromPaymentCustomer.getIsFetchingPaymentCustomer(state.paymentCustomer),
  getPaymentCustomerMessages: state =>
    fromPaymentCustomer.getPaymentCustomerMessages(state.paymentCustomer),

  // Settings
  getIsFetchingSettings: state =>
    fromSettings.getIsFetchingSettings(state.settings),
  getSettings: state => fromSettings.getSettings(state.settings),
  getSettingsMessages: state =>
    fromSettings.getSettingsMessages(state.settings),

  getDocumentByName: (state, name) =>
    fromDocuments.getDocumentByName(state.documents, name),
  getIsFetchingDocuments: state =>
    fromDocuments.getIsFetchingDocuments(state.documents),
  getDocumentsMessages: state =>
    fromDocuments.getDocumentsMessages(state.documents),
  getCartItems: state => fromCart.getCartItems(state.cart),
  getCartItemsDict: state => fromCart.getCartItemsDict(state.cart),
  getSortedCartItems: state => fromCart.getSortedCartItems(state.cart),
  getCartTotal: state => fromCart.getCartTotal(state.cart),
  getCartTotalUnit: state => fromCart.getCartTotalUnit(state.cart),
  getCartCheckoutOpen: state => fromCart.getCartCheckoutOpen(state.cart),
  getBrowseCache: (state, viewName) =>
    fromUICache.getBrowseCache(state.uiCache, viewName),

  // Service Requests
  getWatchlistStatus: state =>
    fromServiceRequests.getWatchlistStatus(state.serviceRequests),
  getWatchlistSelectedRow: state =>
    fromServiceRequests.getWatchlistSelectedRow(state.serviceRequests),
  getWatchlistRequestManagementUrl: state =>
    fromServiceRequests.getWatchlistRequestManagementUrl(state.serviceRequests),
};

// NOTE all these selectors need a reference to `selectors`, so they are added as a separate step.
selectors.getAssetsWithOrgNodes = createSelector(
  [selectors.getAssets, selectors.getOrgNodesDict],
  (assets, orgNodesDict) =>
    assets.map(asset => ({
      ...asset,
      orgNode: orgNodesDict[asset.org_node_id],
    }))
);

selectors.getPagedAssetsWithOrgNodes = createSelector(
  [selectors.getPagedAssets, selectors.getOrgNodesDict],
  (assets, orgNodesDict) => {
    return assets.map(asset => ({
      ...asset,
      organization_node: orgNodesDict[asset.org_node_id],
    }));
  }
);

selectors.getPagedAssetsListWithOrgNodes = createSelector(
  [selectors.getPagedAssetsList, selectors.getOrgNodesDict],
  (assets, orgNodesDict) => {
    if (assets && assets.length > 0) {
      return assets.map(asset => ({
        ...asset,
        organization_node: orgNodesDict[asset.org_node_id],
      }));
    }
    return assets;
  }
);

selectors.getAssetsListSelectedFilters = createSelector(
  [selectors.getPagedAssetsFilters],
  assetsFilters => {
    const selectedArr = [];
    if (assetsFilters) {
      Object.keys(assetsFilters).forEach(a => {
        const selectedFilters = assetsFilters[a].rows.filter(x => x.isSelected);
        if (selectedFilters.length > 0) {
          selectedFilters.forEach(v => {
            selectedArr.push({ key: a, val: v.val });
          });
        }
      });
    }
    return selectedArr;
  }
);

selectors.getOrgLevels = createSelector([selectors.getOrg], org => {
  const {
    data: { labels },
  } = org;

  let level1 = '';
  let level2 = '';
  let level3 = '';
  if (labels && labels.length > 0) {
    if (labels[0])
      level1 = labels[0].level1 && labels[0].level1[0].labelShort.trimEnd();
    if (labels[1])
      level2 = labels[1].level2 && labels[1].level2[0].labelShort.trimEnd();
    if (labels[2])
      level3 = labels[2].level3 && labels[2].level3[0].labelShort.trimEnd();
  }
  return [level1, level2, level3];
});

selectors.getDriverTrainingsExt = createSelector(
  [selectors.getCartItems, selectors.getDriverTrainings],
  (cartItems, trainings) => {
    const set = new Set(cartItems.map(item => item.id));
    return trainings.map(training => ({
      ...training,
      inCart: set.has(training.id),
    }));
  }
);

export const isOrgSetup = org => !!org?.isOrgSetupComplete;

selectors.getIsOrgSetup = createSelector(selectors.getOrg, isOrgSetup);

selectors.getOrgRole = createSelector(
  selectors.getOrg,
  org => org?.currentUserRole
);

selectors.getIsOrgAdmin = createSelector(
  selectors.getOrgRole,
  role => role?.name === 'admin'
);

export { selectors };
