import { Org } from '@holmanfm/lib/common/app-prop-types';
import { isDevelopment } from '@holmanfm/lib/common/environment';
import usePrevious from '@holmanfm/lib/hooks/usePrevious';
import { useTranslation } from '@holmanfm/lib/lang';
import { connect } from '@holmanfm/lib/redux';
import { bootstrap } from '@holmanfm/lib/redux/actions/auth.actions';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { matchPath, Redirect, Switch, useHistory } from 'react-router-dom';
import SEORoute from '~/shared/seo';
import publicRouteSpecs from '~/areas/nav/public-routes';
import LoadingOverlay from '~/shared/components/loading-overlay';
import ServerDownMessage from '~/shared/components/messages/error/server-down-message';
import useBrowserInfo from '~/shared/components/useBrowserInfo';
import Analytics from '~/shared/analytics';
import AppLayout from './layout/app-layout';
import {
  useNavigationRouting,
  useRouting,
} from './hooks/routing/app-routes-context';
import useViewType from './hooks/useViewType';

export const Main = props => {
  const {
    isLoggedIn,
    org,
    bootstrapStatus,
    serverDown,
    isOrgSetup,
    hasLoggedOut,
    bootstrap: bootstrapAction,
  } = props;
  const { t } = useTranslation();
  const {
    location: { pathname },
    params,
  } = useBrowserInfo();

  // check for view query param
  useViewType();

  const history = useHistory();

  // Redirect to the last visited page after logging in - specified in "back" query param..
  const prevIsLoggedIn = usePrevious(isLoggedIn);
  React.useEffect(() => {
    if (prevIsLoggedIn === false && isLoggedIn === true && params.back) {
      history.push(params.back);
    }
  }, [prevIsLoggedIn, isLoggedIn, history, params.back]);

  /**
   * When the application is loaded on a non-public route, we attempt to
   * authenticate and bootstrap the user. If it fails, the user is redirected to the login screen.
   */
  React.useEffect(() => {
    const noFetchRoute = publicRouteSpecs
      .map(({ path }) => path)
      .filter(Boolean)
      .some(path => !!matchPath(pathname, { path }));

    if (bootstrapStatus === 'idle' && hasLoggedOut !== true) {
      if (!noFetchRoute) {
        bootstrapAction().finally(() => Analytics.init());
      } else {
        Analytics.init();
      }
    }
  }, [bootstrapAction, bootstrapStatus, pathname, hasLoggedOut]);

  const filteredNavigation = useNavigationRouting();
  const filteredRoutes = useRouting();
  const Routes =
    bootstrapStatus !== 'resolved'
      ? []
      : filteredRoutes.map(routeProps => (
          // eslint-disable-next-line react/jsx-indent
          <SEORoute key={routeProps.id} {...routeProps} />
        ));

  /**
   * Loading State
   *
   * It's important we dont just check againt isLoading since it's a meta, and the individual
   * loading states can change as the user updates roles and/or organizational information.
   */
  if (bootstrapStatus === 'pending') {
    return (
      <AppLayout>
        <LoadingOverlay display data-testid="loading-overlay" />
      </AppLayout>
    );
  }

  /**
   * Error State
   */
  if (serverDown) {
    Sentry.captureMessage('Server is down', 'error');

    return (
      <AppLayout>
        <ServerDownMessage />
      </AppLayout>
    );
  }

  /**
   * Logged In State
   */
  if (isLoggedIn) {
    const pathIsNotSetupWizard = !pathname.includes(
      '/customer/organization-setup'
    );

    const pathIsNotMyAccount = !pathname.includes('/my-account');

    const shouldRedirect =
      isOrgSetup === false && pathIsNotSetupWizard && pathIsNotMyAccount;

    /**
     * We've logged in but have no associated org. The application is an unusable state.
     *
     * cognito mismatch error - possible only between dev and staging environments (shared pool of users).
     */
    const msg = isDevelopment
      ? t(`common:errors.org-not-found`)
      : t(`common:errors.general`);
    if (!org) {
      return <AppLayout navigationRoutes={filteredNavigation}>{msg}</AppLayout>;
    }

    if (shouldRedirect) {
      return <Redirect to="/customer/organization-setup" />;
    }

    return (
      <AppLayout navigationRoutes={filteredNavigation}>
        <Suspense fallback={null}>
          <Switch>
            <Redirect from="/login" to="/" />
            {Routes}
          </Switch>
        </Suspense>
      </AppLayout>
    );
  }

  /** Logged Out State */
  return (
    <div data-testid="main-logged-out">
      <Suspense fallback={null}>
        <Switch>
          {publicRouteSpecs.map(route => (
            <SEORoute key={route.id} {...route} />
          ))}
        </Switch>
      </Suspense>
    </div>
  );
};

Main.propTypes = {
  /** mapState */
  bootstrapStatus: PropTypes.oneOf(['idle', 'pending', 'resolved', 'rejected'])
    .isRequired,
  hasLoggedOut: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  serverDown: PropTypes.bool.isRequired,
  org: Org,
  isOrgSetup: PropTypes.bool.isRequired,

  /** mapDispatch */
  bootstrap: PropTypes.func.isRequired,
};

Main.defaultProps = {
  org: undefined,
};

function mapState(state) {
  return {
    bootstrapStatus: selectors.getBootstrapStatus(state),
    isLoggedIn: selectors.getLoggedIn(state),
    serverDown: selectors.getServerDown(state),

    hasLoggedOut: selectors.getHasLoggedOut(state),
    org: selectors.getOrg(state),
    isOrgSetup: selectors.getIsOrgSetup(state),
  };
}

const actions = { bootstrap };

const connected = connect(mapState, actions);

export default connected(Main);
