import MuiSwitch from '@mui/material/Switch';
import React from 'react';
import makeStyles from '../makeStyles';

const useStyles = makeStyles(() => ({
  input: {
    left: '-100%',
    width: '300%',
    top: 0,

    cursor: 'inherit',
    height: '100%',
    margin: 0,
    opacity: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
  },
}));

// PrivateSwitchBase-input MuiSwitch-input

const Switch = props => {
  const classes = useStyles();

  const { inputProps, ...rest } = props;
  const mergedInputProps = {
    className: `browser-default ${classes.input}`,
    ...inputProps,
  };

  return <MuiSwitch inputProps={mergedInputProps} {...rest} />;
};

Switch.propTypes = MuiSwitch.propTypes;

Switch.defaultProps = MuiSwitch.defaultProps;

export default Switch;
