/**
 * This file is shared between UI and API. For now, it is manually copied whenever there is a change.
 *
 * This object stores all features supported by the system. The structure of this object is more or less arbitrary.
 * The actual hierarchy of features is defined in roles-layout.ts file.
 */
const Features = {
  DEALERS: {
    PAYMENT_ESTIMATOR: 'DEALERS.PAYMENT_ESTIMATOR',
    START_ENROLLMENT: 'DEALERS.START_ENROLLMENT',
    ADMIN: 'DEALERS.ADMIN',
  },
  MARKETPLACE: {
    INDEX: 'MARKETPLACE',
    ACTION_ITEMS: 'MARKETPLACE.ACTION_ITEMS',
    SHOP_VEHICLES: 'MARKETPLACE.SHOP_VEHICLES',
  },
  CUSTOMERS: {
    LOCATIONS: 'CUSTOMERS.LOCATIONS',
    PAYMENT_HISTORY: 'CUSTOMERS.PAYMENT_HISTORY',
    PAYMENT_OPTIONS: 'CUSTOMERS.PAYMENT_OPTIONS',
    CONSOLIDATED_BILLING: 'CUSTOMERS.CONSOLIDATED_BILLING',
    ROLES: 'CUSTOMERS.ROLES',
    SERVICE_PREFERENCES: 'CUSTOMERS.SERVICE_PREFERENCES',
    SERVICE_MANAGEMENT: 'CUSTOMERS.SERVICE_MANAGEMENT',
    SERVICE_ENROLLMENT: 'CUSTOMERS.SERVICE_ENROLLMENT',
    USERS: 'CUSTOMERS.USERS',
    ORG_SETUP: 'CUSTOMERS.ORG_SETUP',
    ORG_SETUP_BILLING: 'CUSTOMERS.ORG_SETUP_BILLING',
    ORG_SETUP_OEM: 'CUSTOMERS.ORG_SETUP_OEM',
    ORG_SETUP_EMAIL: 'CUSTOMERS.ORG_SETUP_EMAIL',
    ORG_SETUP_DATA: 'CUSTOMERS.ORG_SETUP_DATA',
    ORG_SETUP_FLEET_PROFILE: 'CUSTOMERS.ORG_SETUP_FLEET_PROFILE',
    REQUESTS: 'CUSTOMERS.REQUESTS', // view requests
    CALL_TO_ACTION: 'CUSTOMERS.CALL_TO_ACTION', // view call to action items
  },
  SUPPLIERS: {
    INDEX: 'SUPPLIERS',
    SHARED: {
      MANAGE_MESSAGES: 'MANAGE_MESSAGES',
      MANAGE_CREDIT: 'MANAGE_CREDIT',
      MANAGE_ORDERS: 'MANAGE_ORDERS',
      UPLOAD_FILES: 'UPLOAD_FILES',
    },
  },
  VEHICLES: {
    INDEX: 'VEHICLES',
    VIEW_ORDERS: 'VEHICLES.VIEW_ORDERS',
    VIEW_ASSETS: 'VEHICLES.VIEW_ASSETS',
    INV_MGMT: 'VEHICLES.INV_MGMT',
  },
  ANALYTICS: {
    INDEX: 'ANALYTICS',
  },
  DRIVERS: {
    INDEX: 'DRIVERS',
  },
  DRIVER_TRAINING: {
    INDEX: 'DRIVER_TRAINING',
    TAKE_TRAINING: 'DRIVER_TRAINING.TAKE_TRAINING',
    MANAGEMENT: 'DRIVER_TRAINING.MANAGEMENT',
    PURCHASE: 'DRIVER_TRAINING.PURCHASE',
  },
  SHOP_VEHICLES: {
    INDEX: 'SHOP_VEHICLES',
    VIEW_ORDERS: 'SHOP_VEHICLES.VIEW_ORDERS', // view order listing page
    VIEW_ORDER_DETAILS: 'SHOP_VEHICLES.VIEW_ODER_DETAILS', // view individual order details
    MANAGE_ORDERS: 'SHOP_VEHICLES.MANAGE_ORDERS', // update orders
    ESTABLISH_CREDIT: 'SHOP_VEHICLES.ESTABLISH_CREDIT', // establish a line of credit
    /**
     * @deprecated
     */
    PURCHASE: 'SHOP_VEHICLES.PURCHASE',
    // LEASE: 'SHOP_VEHICLES.LEASE', // !! breaks access ??
    /**
     * @deprecated
     */
    MANAGEMENT: 'SHOP_VEHICLES.MANAGEMENT',
  },
  SHOP_STOCK_VEHICLES: {
    INDEX: 'SHOP_STOCK_VEHICLES',
    VIEW_ORDERS: 'SHOP_STOCK_VEHICLES.VIEW_ORDERS',
    PURCHASE: 'SHOP_STOCK_VEHICLES.PURCHASE', // requesting a quote
    MANAGEMENT: 'SHOP_STOCK_VEHICLES.MANAGEMENT',
  },
  SHOP_FACTORY_VEHICLES: {
    INDEX: 'SHOP_FACTORY_VEHICLES',
    VIEW_ORDERS: 'SHOP_FACTORY_VEHICLES.VIEW_ORDERS',
    // for VIEW_ORDERS, it's the same as SHOP_VEHICLES.VIEW_ORDERS
    PURCHASE: 'SHOP_FACTORY_VEHICLES.PURCHASE', // requesting a quote
    MANAGEMENT: 'SHOP_FACTORY_VEHICLES.MANAGEMENT',
  },
  SHOP_POOL_VEHICLES: {
    INDEX: 'SHOP_POOL_VEHICLES',
    VIEW_ORDERS: 'SHOP_POOL_VEHICLES.VIEW_ORDERS',
    // for VIEW_ORDERS, it's the same as SHOP_VEHICLES.VIEW_ORDERS
    PURCHASE: 'SHOP_POOL_VEHICLES.PURCHASE', // requesting a quote
  },
  REMARKETING: 'REMARKETING',
  BUYDIRECT: 'REMARKETING.BUYDIRECT',
  PLB: 'REMARKETING.PLB',
  SERVICE_MAINT: {
    // now known as maintenance and repair
    INDEX: 'SERVICE_MAINT',
    /**
     * @todo the following features are not used yet
     */
    VENDOR_LOCATOR: 'SERVICE_MAINT.VENDOR_LOCATOR',
    PREFERRED_VENDOR: 'SERVICE_MAINT.PREFERRED_VENDOR',
    VIEW_REQUEST_PREFERENCES: 'SERVICE_MAINT.VIEW_REQUEST_PREFERENCES',
    UPDATE_REQUEST_PREFERENCES: 'SERVICE_MAINT.UPDATE_REQUEST_PREFERENCES',
  },
  TELEMETRY: {
    INDEX: 'TELEMETRY',
    DASHBOARD: 'TELEMETRY.DASHBOARD',
  },
  TELEMATICS_GEOTAB: {
    INDEX: 'TELEMATICS_GEOTAB',
    VIEW_DASHBOARD: 'VIEW_DASHBOARD',
    ORDER_HARDWARE: 'ORDER_HARDWARE',
  },

  TELEMATICS_AZUGA: 'TELEMATICS_AZUGA',

  PM_NOTIFICATION: {
    INDEX: 'PM_NOTIFICATION',
    VIEW_SCHEDULE: 'PM_NOTIFICATION.VIEW_SCHEDULE',
    UPDATE_SCHEDULE: 'PM_NOTIFICATION.UPDATE_SCHEDULE',
  },
  ASSETS: {
    INDEX: 'ASSETS',
    CREATE: 'ASSETS.CREATE',
    UPDATE: 'ASSETS.UPDATE',
    /**
     * @deprecated
     */
    VIEW: 'ASSETS.VIEW',
  },
  FUEL_MANAGEMENT: {
    INDEX: 'FUEL_MANAGEMENT',
    /**
     * @todo the following features are not used yet
     */
    MANAGE_PREFERENCES: 'FUEL_MANAGEMENT.MANAGE_PREFERENCES',
    MANAGE_FUEL_CARDS: 'FUEL_MANAGEMENT.MANAGE_FUEL_CARDS',
    MANAGE_FUEL_PINS: 'FUEL_MANAGEMENT.MANAGE_FUEL_PINS',
    MANAGE_FUEL_PROFILES: 'FUEL_MANAGEMENT.MANAGE_FUEL_PROFILES',
    VIEW_TRANSACTIONS: 'FUEL_MANAGEMENT.VIEW_TRANSACTIONS',
  },
  PARENT_ORG: {
    INDEX: 'PARENT_ORG',
  },
  INSURANCE: {
    INDEX: 'INSURANCE',
  },
  ACCIDENT_REPORTING: {
    INDEX: 'ACCIDENT_REPORTING',
  },
  ROADSIDE_ASSIST: {
    INDEX: 'ROADSIDE_ASSIST',
  },
  PAY_MY_BILL: {
    INDEX: 'PAY_MY_BILL',
  },
  HELP_ADMIN: 'HELP_ADMIN',
  /**
   * This feature does not grant any permissions.
   * It is used to create roles with no permissions since "[]" indicatates an admin
   */
  PLACEHOLDER: 'PLACEHOLDER',
};

export default Features;

/**
 * Dictiorary that maps service names to their corresponding index features
 */
export const ServiceIndexFeatures = {
  'stock-vehicle-acquisition': Features.MARKETPLACE.SHOP_VEHICLES,
  'service-maint': Features.SERVICE_MAINT.INDEX,
  'driver-training': Features.DRIVER_TRAINING.INDEX,
  'fuel-management': Features.FUEL_MANAGEMENT.INDEX,
  telematics: Features.TELEMETRY.INDEX,
  'telematics-motorq': Features.TELEMETRY.INDEX,
  'telematics-geotab': Features.TELEMATICS_GEOTAB.INDEX,
  remarketing: Features.REMARKETING,
  insurance: Features.INSURANCE.INDEX,
  'accident-reporting': Features.ACCIDENT_REPORTING.INDEX,
  'roadside-assist': Features.ROADSIDE_ASSIST.INDEX,
  'pm-notification': Features.PM_NOTIFICATION.INDEX,
  'pay-my-bill': Features.PAY_MY_BILL.INDEX,
};

// Simple depth first traversal.
const deepObjectToArray = deepObject => {
  const result = [];
  let elem;
  const stack = [deepObject];

  while (stack.length > 0) {
    elem = stack.pop();
    if (typeof elem !== 'object') {
      result.push(elem);
    } else {
      stack.push(...Object.values(elem));
    }
  }

  return result;
};

export const allFeaturesArr = deepObjectToArray(Features);

const consumerFeatures = { ...Features };
delete consumerFeatures.SUPPLIERS;
export const consumerFeaturesArr = deepObjectToArray(consumerFeatures);

const defaultFeatures = { ...Features.CUSTOMERS };
delete defaultFeatures.SERVICE_PREFERENCES;
delete defaultFeatures.USERS;
delete defaultFeatures.ROLES;
delete defaultFeatures.PAYMENT_HISTORY;
export const defaultFeaturesArr = deepObjectToArray(defaultFeatures);

const baseServiceFeatures = { ...Features.CUSTOMERS };
delete baseServiceFeatures.SERVICE_PREFERENCES;
delete baseServiceFeatures.SERVICE_MANAGEMENT;
delete baseServiceFeatures.SERVICE_ENROLLMENT;
delete baseServiceFeatures.PAYMENT_OPTIONS;
delete baseServiceFeatures.LOCATIONS;
delete baseServiceFeatures.USERS;
delete baseServiceFeatures.ROLES;
export const baseServiceFeaturesArr = deepObjectToArray(baseServiceFeatures);

const fordDefaultFeatures = { ...defaultFeatures, ...baseServiceFeatures };
delete fordDefaultFeatures.PAYMENT_OPTIONS;
delete fordDefaultFeatures.PAYMENT_HISTORY;
export const defaultFordFeaturesArr = deepObjectToArray(fordDefaultFeatures);
