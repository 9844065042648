import MUIIconButton from '@mui/material/IconButton';
import clx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const IconButton = ({ className, ...rest }) => (
  <MUIIconButton className={clx('browser-default', className)} {...rest} />
);

IconButton.propTypes = {
  className: PropTypes.string,
};

IconButton.defaultProps = {
  className: undefined,
};

export default IconButton;
