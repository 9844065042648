import React from 'react';
import PropTypes from 'prop-types';
import clx from 'classnames';
import PaginationItem from '@mui/material/PaginationItem';
import { useTranslation } from '@holmanfm/lib/lang';
import Box from '~/shared/components/box';
import makeStyles from '~/shared/components/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    lineHeight: 1.75,
    alignItems: 'center',
    '& .MuiPaginationItem-root': {
      color: theme.palette.grey[600],
    },
    '& .MuiPaginationItem-sizeSmall': {
      height: '20px',
      minWidth: '20px',
      '& .MuiPaginationItem-icon': {
        fontSize: '1rem',
      },
    },
  },
  display: {
    color: theme.palette.grey[500],
  },
}));

const MinimalPagination = React.forwardRef(
  (
    {
      page,
      totalPages,
      handlePageChange,
      entityNamePlural,
      entityNameSingular,
      from,
      to,
      total,
      className,
      size,
    },
    ref
  ) => {
    const classes = useStyles();
    const { t } = useTranslation('browse');
    const resultsDisplay = t('results', {
      from,
      to,
      total,
      type: (total > 0 ? entityNamePlural : entityNameSingular).toLowerCase(),
    });

    return (
      <Box className={clx(classes.root, className)} my={1}>
        {totalPages > 1 && (
          <>
            <PaginationItem
              className="browser-default"
              ref={ref}
              type="previous"
              variant="outlined"
              shape="rounded"
              size={size}
              color="secondary"
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            />
            <Box className={classes.display} mx={1}>
              {resultsDisplay}
            </Box>
            <PaginationItem
              className="browser-default"
              ref={ref}
              type="next"
              variant="outlined"
              shape="rounded"
              size={size}
              color="secondary"
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
            />
          </>
        )}
      </Box>
    );
  }
);

MinimalPagination.propTypes = {
  handlePageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  entityNamePlural: PropTypes.string,
  entityNameSingular: PropTypes.string,
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
};

MinimalPagination.defaultProps = {
  entityNamePlural: '',
  entityNameSingular: '',
  className: '',
  size: 'medium',
};

export default React.memo(MinimalPagination);
