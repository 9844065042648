import React from 'react';
import { useNSTranslation } from '@holmanfm/lib/lang';
import HomeButton from '~/areas/nav/home-button';
import IconButton from '~/shared/components/atom/icon-button';
import Toolbar from '~/shared/components/bar/toolbar';
import Box from '~/shared/components/box';
import List from '~/shared/components/list/list';

import Menu from '~/shared/icons/menu';
import Avatar from '~/shared/icons/avatar';
import makeStyles from '~/shared/components/makeStyles';

import { privateAppBase } from '~/shared/environment';
import PublicNavLink from './public-nav-link';
import Drawer from '~/shared/components/drawer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbarButtons: {
    '& > a': {
      color: '#fff',
      textDecoration: 'none',
    },
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
    },
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  toolbar: {
    backgroundColor: '#003057',
    color: '#fff',
  },
  navigationBar: {
    top: 0,
    left: 'auto',
    right: 0,
    position: 'sticky',
    width: '100%',
    display: 'flex',
    zIndex: 1100,
    boxSizing: 'border-box',
    flexShrink: 0,
    flexDirection: 'column',
  },
}));

export const Nav = () => {
  const classes = useStyles();
  const { t } = useNSTranslation('main');

  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div color="default" className={classes.navigationBar}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex">
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <IconButton
              className="browser-default"
              onClick={toggleSidebar}
              style={{ color: '#fff' }}
            >
              <Menu />
            </IconButton>
          </Box>

          <HomeButton />

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <List
              style={{
                height: 64,
                display: 'flex',
                flexDirection: 'row',
                padding: 0,
              }}
            >
              <PublicNavLink />
            </List>
          </Box>

          <Drawer
            anchor="left"
            open={sidebarOpen}
            onClose={toggleSidebar}
            PaperProps={{ classes }}
          >
            <PublicNavLink />
          </Drawer>
        </Box>

        <div className={classes.toolbarButtons}>
          <Avatar />
          <a href={`${privateAppBase}/login`}>{t('public.login')}</a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a href={`${privateAppBase}/signup`}>{t('public.signup')}</a>
        </div>
      </Toolbar>
    </div>
  );
};

Nav.propTypes = {};

Nav.defaultProps = {};

export default Nav;
