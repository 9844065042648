/* eslint-disable react/no-array-index-key */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import Typography from '../components/atom/typography';

export const renderContent = (content, theme) => {
  return content.split('\n').map((line, index) => {
    // Skip empty lines
    if (!line.trim()) return null;

    // Check if this is a numbered list item
    const isNumberedList = line.match(/^\d+\./);

    // Format markdown-style text with simple replacements
    let formattedLine = line;

    // Replace ** with <strong> tags for bold
    formattedLine = formattedLine.replace(
      /\*\*(.*?)\*\*/g,
      '<strong>$1</strong>'
    );

    // Replace * with <em> tags for italic
    formattedLine = formattedLine.replace(/\*(.*?)\*/g, '<em>$1</em>');

    return (
      <Typography
        key={index}
        variant="body1"
        style={{
          marginBottom: theme.spacing(isNumberedList ? 1 : 2),
          marginLeft: isNumberedList ? theme.spacing(2) : 0,
        }}
        // it's okay to use dangerouslySetInnerHTML here because we're not
        // rendering user input, but rather an admin-defined help text
        dangerouslySetInnerHTML={{ __html: formattedLine }}
      />
    );
  });
};
