import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from '../redux';
import { withTranslation } from '../lang';

export const actionCreatorBinder = actionCreators => dispatch =>
  bindActionCreators(actionCreators, dispatch);

/**
 * This high-order component will 'connect' a component if stateToProp or actionCreators are
 * provided. If the actionCreators are supplied, they are provided to bindActionCreators.
 *
 * The expectation is to supply react-redux's `connect` function, which will then connect
 * the component to Redux and bind the action creators to the store.
 *
 * @param {*} [mapState]
 * @param {bindActionCreators} bindActionCreatorsFn
 * @param {connect} connectHoc
 */
export const maybeConnectGenerator = actionCreatorBinderFn => connectHoc => (
  mapState,
  actionCreators
) => {
  if (mapState || actionCreators) {
    return connectHoc(
      mapState,
      actionCreators ? actionCreatorBinderFn(actionCreators) : undefined
    );
  }
  return component => component;
};

/**
 * The resulting high-order component wraps a component in connectHoc and maybeConnect.
 *
 * @param {Function} maybeConnectHoc - A high-order component that might subscribes to global state.
 * @param {Function} translationHoc - A high-order component that provides translation services.
 */
export const hocGenerator = (maybeConnectHoc, translationHoc) => (
  mapState,
  actionCreators
) => component => {
  const TranslatedComponent = translationHoc()(component);
  return maybeConnectHoc(mapState, actionCreators)(TranslatedComponent);
};

hocGenerator.propTypes = {
  t: PropTypes.func,
};

export default hocGenerator(
  maybeConnectGenerator(actionCreatorBinder)(connect),
  withTranslation
);
