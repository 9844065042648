import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { useAceControls, useAceSelectList } from '@holmanfm/lib/hooks/ace';
import Select, { MenuItem } from '~/shared/components/forms/select';
import SubmitButton from '~/shared/components/forms/submit-button';
import Typography from '~/shared/components/atom/typography';

const ACESession = () => {
  const { t } = useNSTranslation('customerService', 'ace.component');
  const { isAceSessionActive, startAceSession } = useAceControls();
  const { selectListData } = useAceSelectList();

  return (
    <div>
      <Typography gutterBottom variant="h4" style={{ marginTop: '1em' }}>
        {t('title')}
      </Typography>
      <div>
        <Formik
          initialValues={{ userId: '', orgId: '' }}
          onSubmit={(values) => {
            startAceSession(values.userId, values.orgId).catch((err) => {
              console.warn('Failed to start ACE session: ', err);
            });
          }}
          validationSchema={Yup.object({
            userId: Yup.string().required(),
            orgId: Yup.string().required(),
          })}
        >
          {(formikProps) => {
            const handleUserChange = (evt) => {
              formikProps.setFieldValue('userId', '');
              formikProps.handleChange(evt);
            };
            return (
              <Form>
                <Select
                  disabled={isAceSessionActive}
                  name="orgId"
                  id="orgId"
                  label={t('select-list-label-org')}
                  variant="outlined"
                  displayEmpty
                  notched
                  fullWidth
                  style={{ marginTop: '1em' }}
                >
                  <MenuItem value="">{t('select-list-empty-org')}</MenuItem>
                  {selectListData &&
                    selectListData.map((org) => (
                      <MenuItem key={org.orgValue} value={org.orgValue}>
                        {org.orgLabel}
                      </MenuItem>
                    ))}
                </Select>
                <Select
                  disabled={isAceSessionActive}
                  name="userId"
                  id="userId"
                  label={t('select-list-label-user')}
                  variant="outlined"
                  displayEmpty
                  notched
                  fullWidth
                  style={{ marginTop: '1em', marginBottom: '1em' }}
                  handleChange={handleUserChange}
                >
                  <MenuItem value="">{t('select-list-empty-user')}</MenuItem>
                  {selectListData
                    .find((item) => item.orgValue === formikProps.values.orgId)
                    ?.orgUsers.map((user) => (
                      <MenuItem key={user.userValue} value={user.userValue}>
                        {user.userLabel}
                      </MenuItem>
                    ))}
                </Select>
                <SubmitButton
                  disabled={isAceSessionActive}
                  id="start-ace-session"
                >
                  {t('button-start')}
                </SubmitButton>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ACESession;
