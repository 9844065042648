import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { isEmptyObject } from '@holmanfm/lib/util/helpers';
import Dialog from '~/shared/components/dialog';
import Slide from '~/shared/components/transitions/slide';
import Toolbar from '~/shared/components/bar/toolbar';
import IconButton from '~/shared/components/atom/icon-button';
import Close from '~/shared/icons/close';
import Typography from '~/shared/components/atom/typography';
import makeStyles from '~/shared/components/makeStyles';
import DialogActions from '~/shared/components/dialog-actions';
import DialogContent from '~/shared/components/dialog-content';
import Button from '~/shared/components/atom/button';
import GreenDriveBar from '~/shared/icons/green-drive-bar';
import AppBar from '~/shared/components/bar/appbar';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'pre-line',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 12,
  },
  columnLeftRight: {
    '& img': {
      maxWidth: '40%',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      '& img': {
        maxWidth: '100%',
      },
    },
  },
}));

const StyledDialog = ({
  isOpen,
  onClose,
  dialogTitle,
  btnActions,
  children,
  hasColumns,
  columns,
  widthSize,
  closeText,
  maxHeight,
  fullScreen,
  maxWidth,
  ...rest
}) => {
  const classes = useStyles();
  const {
    btnTitle,
    btnFunc,
    disabled,
    closeBtn,
    closeFunc,
    secondTitle,
    secondBtnFunc,
    secondDisabled,
    cancelBtnFunc,
  } = btnActions;
  const { leftCol, rightCol } = columns;
  const { t } = useNSTranslation('main', 'dialog');
  const noButtons = !btnActions || isEmptyObject(btnActions);

  const columnWidths = () => {
    if (widthSize === 'half') {
      return {
        left: 1,
        right: 1,
      };
    }
    if (widthSize === 'left') {
      return {
        left: 1,
        right: 0.5,
      };
    }
    if (widthSize === 'right') {
      return {
        left: 0.5,
        right: 1,
      };
    }
    return {
      left: 1,
      right: 1,
    };
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      fullWidth
      maxWidth={maxWidth}
      className={classes.root}
      fullScreen={fullScreen}
      {...rest}
    >
      {fullScreen ? (
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
      ) : (
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="Close"
          >
            <Close />
          </IconButton>
          <div style={{ marginTop: '8px', lineHeight: '.65rem' }}>
            <Typography variant="h3">{dialogTitle}</Typography>
            <GreenDriveBar />
          </div>
        </Toolbar>
      )}
      <DialogContent
        style={fullScreen ? { marginTop: '70px' } : { maxHeight }}
        sx={{ pt: 1 }}
      >
        {fullScreen && (
          <div style={{ margin: '24px 0', lineHeight: '.65rem' }}>
            <Typography variant="h2">{dialogTitle}</Typography>
            <GreenDriveBar />
          </div>
        )}
        {hasColumns && leftCol && rightCol ? (
          <>
            {children && <div style={{ marginBottom: 16 }}>{children}</div>}
            <div className={classes.columnLeftRight}>
              <div style={{ flex: columnWidths()?.left }}>{leftCol}</div>
              <div style={{ flex: columnWidths()?.right }}>{rightCol}</div>
            </div>
          </>
        ) : (
          <div>{children}</div>
        )}
      </DialogContent>
      <DialogActions>
        {noButtons ? (
          <div style={{ marginTop: 16 }} />
        ) : (
          <div style={{ marginTop: 32, paddingRight: 12, paddingBottom: 12 }}>
            {closeBtn ? (
              <Button
                color="secondary"
                onClick={closeFunc}
                variant="contained"
                style={{ marginRight: 8 }}
                id="close-btn"
              >
                {t('close')}
              </Button>
            ) : (
              <Button
                color="secondary"
                onClick={cancelBtnFunc || onClose}
                style={{ marginRight: 8 }}
                id="cancel-btn"
              >
                {closeText || t('cancel')}
              </Button>
            )}
            {secondBtnFunc && (
              <Button
                onClick={secondBtnFunc}
                color="secondary"
                variant="outlined"
                style={{ marginRight: 8 }}
                disabled={secondDisabled}
                id="second-btn"
              >
                {secondTitle}
              </Button>
            )}
            {btnFunc && (
              <Button
                onClick={btnFunc}
                color="secondary"
                disabled={disabled}
                variant="contained"
                id="main-btn"
              >
                {btnTitle}
              </Button>
            )}
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

StyledDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  btnActions: PropTypes.shape({
    btnFunc: PropTypes.func,
    btnTitle: PropTypes.string,
    disabled: PropTypes.bool,
    closeFunc: PropTypes.func,
    closeBtn: PropTypes.bool,
    secondTitle: PropTypes.string,
    secondBtnFunc: PropTypes.func,
    secondDisabled: PropTypes.bool,
    cancelBtnFunc: PropTypes.func,
  }),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  hasColumns: PropTypes.bool,
  columns: PropTypes.shape({
    leftCol: PropTypes.shape({}),
    rightCol: PropTypes.shape({}),
  }),
  widthSize: PropTypes.string,
  closeText: PropTypes.string,
  maxHeight: PropTypes.string,
  fullScreen: PropTypes.bool,
  maxWidth: PropTypes.string,
};

StyledDialog.defaultProps = {
  btnActions: undefined,
  children: undefined,
  hasColumns: false,
  columns: { leftCol: null, rightCol: null },
  widthSize: undefined,
  closeText: undefined,
  maxHeight: 'none',
  fullScreen: false,
  maxWidth: 'sm',
};

export default StyledDialog;
