import React from 'react';
import PropTypes from 'prop-types';

import PaginationItem from '@mui/material/PaginationItem';
import { useNSTranslation } from '@holmanfm/lib/lang';
import Box from '~/shared/components/box';
import { useStyles as usePagingStyles } from '~/shared/components/molecule/mui-pagination/minimal-pagination';

const BackButton = ({ goBack }) => {
  const pagingClasses = usePagingStyles();
  const { t } = useNSTranslation('telemetry');

  return (
    <Box className={pagingClasses.root} mt={1} mb={1} onClick={goBack}>
      <PaginationItem
        className="browser-default"
        type="previous"
        variant="outlined"
        shape="rounded"
        size="small"
        color="secondary"
      />
      <Box className={pagingClasses.display} mx={1}>
        {t('return-to-results')}
      </Box>
    </Box>
  );
};

BackButton.propTypes = {
  goBack: PropTypes.func.isRequired,
};

export default BackButton;
