import React from 'react';

const withLogoutHandlers = WrappedComponent => {
  return props => {
    const redirect = React.useCallback(
      redirectUrl => {
        props.history.push(redirectUrl);
      },
      [props.history]
    );

    return <WrappedComponent {...props} redirect={redirect} />;
  };
};

export default withLogoutHandlers;
