/* eslint-disable no-return-await */
import API from './api';
import { BASE_URL } from '../common/environment';

export class AuthActionRequired extends Error {}

class AuthService {
  async bootstrap() {
    const data = (await API.get('/bootstrap')).payload;
    data.user.currentOrgId = data.user.selectedOrgId;
    return data;
  }

  async checkAccount(userName, authUrl = null) {
    userName = encodeURIComponent(userName);
    let url = `/account/${userName}/interrogate`;
    if (authUrl) url += `/${authUrl}`;

    const data = await API.get(url);
    return data.payload.status;
  }

  async resetPasswordRequest(email, path) {
    // const data = await API.post('/account/reset-password',
    // { userName, oldPassword, newPassword });
    // const { IdToken, AccessToken, RefreshToken } = data.payload;
    // API.setTokens({ IdToken, AccessToken });
    // return { IdToken, AccessToken, RefreshToken };
    const data = await API.post('/authenticate/reset-password-request', {
      email,
      path,
    });
    return data;
  }

  async resetPassword(code, email, password) {
    // const data = await API.post('/account/reset-password',
    // { userName, oldPassword, newPassword });
    // const { IdToken, AccessToken, RefreshToken } = data.payload;
    // API.setTokens({ IdToken, AccessToken });
    // return { IdToken, AccessToken, RefreshToken };
    const data = await API.post('/authenticate/reset-password', {
      code,
      email,
      password,
    });
    return data;
  }

  async loadUserData() {
    const user = (await API.get('/users/account')).payload;
    user.currentOrgId = user.selectedOrgId;
    return user;
  }

  // NOTE call this function only after calling checkAccount and checking the status.
  // in case of failed login throws NotAuthenticatedError.
  async signUp(data, authUrl = null) {
    let url = '/signup';
    if (authUrl) url += `/${authUrl}`;
    const responseData = await API.post(url, data);

    // TODO return something useful.
    return responseData;
  }

  async newUserFinal(code, email, credential, authUrl = null) {
    let url = '/signup/finalize';
    if (authUrl) url += `/${authUrl}`;
    await API.post(url, { code, email, credential });

    // const authToken = data.payload.token;
    // API.setToken(authToken);
    // return authToken;

    return null;
  }

  async getAuthDetails(authUrl) {
    const data = await API.get(`/authenticate/details/${authUrl}`);
    return data.payload;
  }

  async authenticate(username, password, authUrl = null) {
    let url = '/authenticate';
    if (authUrl) url += `/${authUrl}`;
    await API.post(url, { username, password });
  }

  async authenticateMobile(username, password, authUrl = null) {
    let url = '/authenticate/mobile';
    if (authUrl) url += `/${authUrl}`;
    await API.post(url, { username, password });
  }

  async logOut() {
    return await API.post('/authenticate/logout', {});
  }
}

const authService = new AuthService({ baseURL: BASE_URL }); // singleton
export default authService;
