import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams, matchPath } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getPageHelp } from '@holmanfm/lib/services/help';
import { useNSTranslation } from '@holmanfm/lib/lang';
import HelpOutline from '../icons/help-outline';
import AssignmentTurnedIn from '../icons/assignment-turned-in';
import QuestionAnswer from '../icons/question-answer';
import Description from '../icons/description';
import useTheme from '~/shared/components/useTheme';
import StyledDialog from '~/shared/components/styled-dialog';
import Tooltip from '~/shared/components/molecule/tooltip';
import IconButton from '~/shared/components/atom/icon-button';
import Typography from '~/shared/components/atom/typography/typography';
import Tabs from '~/shared/components/tabs';
import Tab from '~/shared/components/tab';
import List from '~/shared/components/list/list';
import ListItem from '~/shared/components/list/list-item';
import ListItemText from '~/shared/components/list/list-item-text';
import Badge from '~/shared/components/badge';
import { renderContent } from './util';
import { useRouting } from '~/hooks/routing/app-routes-context';

export const PageHelp = ({ fallback }) => {
  const { t } = useNSTranslation('main', 'help.page-help');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState('doc');
  const theme = useTheme();

  const location = useLocation();

  const filteredRoutes = useRouting();
  const params = useParams();
  const path = React.useMemo(() => {
    /**
     * Since some routes are not static, we need to
     * iterate all active routes and run matching logic to find the current one
     */
    if (Object.keys(params).length > 0) {
      const matchedRoute = filteredRoutes.find((route) =>
        matchPath(location.pathname, {
          path: route.path,
          exact: true,
          strict: false,
        })
      );
      return matchedRoute?.path || location.pathname;
    }
    // Not a dynamic route
    return location.pathname;
  }, [filteredRoutes, location.pathname, params]);

  const { data: pageContent, isLoading } = useQuery({
    queryKey: ['page-help', path],
    queryFn: async () => getPageHelp(path).then((res) => res.payload),
  });

  if (isLoading || !pageContent) {
    return fallback;
  }

  const { title, description, details } = pageContent;

  // Group details by type
  const groupedDetails = details.reduce((acc, detail) => {
    if (!acc[detail.type]) {
      acc[detail.type] = [];
    }
    acc[detail.type].push(detail);
    return acc;
  }, {});

  const tabContent = {
    doc: {
      icon: <Description fontSize="small" />,
      label: t('doc-label'),
      items: groupedDetails.doc || [],
      emptyText: t('no-help', { name: 'documentation' }),
    },
    tasks: {
      icon: <AssignmentTurnedIn fontSize="small" />,
      label: t('task-label'),
      items: groupedDetails.task || [],
      emptyText: t('no-help', { name: 'tasks' }),
    },
    faq: {
      icon: <QuestionAnswer fontSize="small" />,
      label: t('faq-label'),
      items: groupedDetails.faq || [],
      emptyText: t('no-help', { name: 'FAQs' }),
    },
  };

  return (
    <section>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          marginBottom: theme.spacing(1),
        }}
      >
        <Typography variant="h1">{title}</Typography>
        <Tooltip lightTooltip title={t('tooltip')}>
          <div>
            <IconButton
              onClick={() => setDialogOpen(true)}
              aria-label={t('tooltip')}
              style={{ color: 'text.secondary' }}
            >
              <HelpOutline />
            </IconButton>
          </div>
        </Tooltip>
      </div>

      <Typography variant="body2" style={{ marginBottom: 32 }}>
        {description}
      </Typography>

      <StyledDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        btnActions={{ closeBtn: true, closeFunc: () => setDialogOpen(false) }}
        dialogTitle={title}
        maxWidth="md"
        PaperProps={{
          style: {
            height: '80vh', // Fixed height for dialog
          },
        }}
      >
        <Typography variant="body2" color="textSecondary" sx={{ pb: 2 }}>
          {description}
        </Typography>
        <Tabs
          value={selectedTab}
          onChange={(_, newValue) => setSelectedTab(newValue)}
          style={{
            borderBottom: '1px solid #e0e0e0',
            minHeight: '48px',
          }}
        >
          {Object.entries(tabContent).map(([key, { icon, label, items }]) => (
            <Tab
              key={key}
              value={key}
              style={{ minHeight: '48px' }}
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Badge
                    badgeContent={items.length}
                    color="secondary"
                    overlap="rectangular"
                  >
                    {icon}
                  </Badge>
                  <span style={{ marginLeft: '1em' }}>{label}</span>
                </div>
              }
            />
          ))}
        </Tabs>

        {Object.entries(tabContent).map(([key, { items, emptyText }]) => (
          <div
            key={key}
            role="tabpanel"
            hidden={selectedTab !== key}
            id={`tabpanel-${key}`}
            aria-labelledby={`tab-${key}`}
            style={{
              height: '100%',
              display: selectedTab === key ? 'block' : 'none',
            }}
          >
            {selectedTab === key &&
              (items.length > 0 ? (
                <List style={{ padding: 0 }}>
                  {items.map((detail, index) => (
                    <ListItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      style={{
                        display: 'block',
                        borderBottom:
                          index < items.length - 1
                            ? '1px solid #e0e0e0'
                            : undefined,
                        padding: theme.spacing(2),
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            component="div"
                            variant="h6"
                            color="primary"
                            gutterBottom
                          >
                            {detail.label}
                          </Typography>
                        }
                        secondary={
                          <div style={{ marginTop: theme.spacing(1) }}>
                            {renderContent(detail.content, theme)}
                          </div>
                        }
                        secondaryTypographyProps={{
                          component: 'div',
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography
                  color="textSecondary"
                  style={{ padding: theme.spacing(2) }}
                >
                  {emptyText}
                </Typography>
              ))}
          </div>
        ))}
      </StyledDialog>
    </section>
  );
};

PageHelp.propTypes = {
  fallback: PropTypes.element.isRequired,
};

export default PageHelp;
