import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { formatMoney } from '@holmanfm/lib/common/marketplace-helpers';
import Table from '~/shared/components/table';
import TableBody from '~/shared/components/table-body';
import TableCell from '~/shared/components/table-cell';
import TableRow from '~/shared/components/table-row';
import TableHead from '~/shared/components/table-head';
import withStyles from '~/shared/components/withStyles';
import makeStyles from '~/shared/components/makeStyles';
import Typography from '~/shared/components/atom/typography';
import typeStyles from '~/shared/components/styles/global-type-styles';
import Radio from '~/shared/components/radio';

const useStyles = makeStyles(() => ({
  profitCellBG: {
    '&:nth-child(even) > .profit': {
      background: '#DDEBF4',
    },
    '&:nth-child(odd) > .profit': {
      background: '#D1E1ED',
    },
    '&:nth-child(odd) > .terms': {
      background: '#F2F4F7',
    },
  },
}));

const BorderlessTableCell = withStyles((theme) => ({
  root: {
    border: 'none',
    padding: theme.spacing(1),
  },
}))(TableCell);

const ProfitRow = ({ profit = '', isSelected }) => {
  return (
    <BorderlessTableCell
      align="right"
      className="profit"
      style={{ fontWeight: isSelected ? 'bold' : '' }}
    >
      {profit ? formatMoney(profit) : ''}
    </BorderlessTableCell>
  );
};

ProfitRow.propTypes = {
  profit: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

const TermRow = ({ data, title, updateSelection, selectedLease }) => {
  const classes = useStyles();

  const { profit, leaseEstimate, residual, residualDollars, term } = data;

  const isSelected = selectedLease === `${title}-${leaseEstimate}`;

  return (
    <TableRow className={classes.profitCellBG}>
      <BorderlessTableCell
        className="terms"
        style={{ fontWeight: isSelected ? 'bold' : '' }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Radio
            checked={isSelected}
            value={`${title}-${leaseEstimate}`}
            id={`${title}-${leaseEstimate}`}
            label=""
            control={<Radio />}
            onChange={(e) =>
              updateSelection(e.target.value, {
                leaseEstimate,
                residual,
                residualDollars,
                term,
              })
            }
          />
          <div style={{ marginLeft: 16, fontWeight: isSelected ? 'bold' : '' }}>
            {residual}% / {formatMoney(residualDollars)}
          </div>
        </div>
      </BorderlessTableCell>
      <BorderlessTableCell
        className="terms"
        style={{ fontWeight: isSelected ? 'bold' : '' }}
      >
        {formatMoney(leaseEstimate)} *
      </BorderlessTableCell>
      <ProfitRow profit={profit} isSelected={isSelected} />
    </TableRow>
  );
};

TermRow.propTypes = {
  data: PropTypes.shape({
    profit: PropTypes.string,
    id: PropTypes.number,
    leaseEstimate: PropTypes.string,
    residual: PropTypes.number,
    residualDollars: PropTypes.string,
    term: PropTypes.number,
    selected: PropTypes.bool,
  }).isRequired,
  selectedLease: PropTypes.string,
  updateSelection: PropTypes.func.isRequired,
  title: PropTypes.number.isRequired,
};

TermRow.defaultProps = {
  selectedLease: undefined,
};

const LeasingOptionsChart = (props) => {
  const {
    leasingOptions,
    updateSelection,
    chosenOptions,
    selectedLease,
    setSelectedLease,
  } = props;
  const typeClasses = typeStyles();
  const { t } = useNSTranslation('dealerPortal', 'payment-estimator');

  const handleLeaseUpdate = (value, data) => {
    setSelectedLease(value);
    updateSelection(data);
  };

  return (
    <div style={{ maxWidth: 700, margin: '0 auto' }}>
      <Table id="leasing-chart" style={{ marginBottom: 8 }}>
        <TableHead>
          <TableRow>
            <BorderlessTableCell>
              <Typography variant="body2">
                <em>{t('only-one-selection')}</em>
              </Typography>
            </BorderlessTableCell>
          </TableRow>
        </TableHead>
      </Table>
      {Object.values(leasingOptions[0])?.map((td) => (
        <Table
          id="leasing-chart-options"
          key={td[0]?.term}
          style={{ marginBottom: 40 }}
        >
          <TableHead>
            <TableRow>
              <BorderlessTableCell
                style={{
                  background: '#003057',
                  color: '#fff',
                  fontWeight: 'bold',
                }}
                colSpan={2}
              >
                {t('terms')} {td[0]?.term} {t('months')}
              </BorderlessTableCell>
              <BorderlessTableCell
                style={{
                  background: '#0069A7',
                  color: '#fff',
                  fontWeight: 'bold',
                }}
              >
                {t('dealer-profit')}
              </BorderlessTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {td?.map((d, index) => (
              <Fragment
                id={`${d?.term}-${d?.leaseEstimate}`}
                key={`${d?.term}-${d?.leaseEstimate}`}
              >
                {index === 0 && (
                  <TableRow>
                    <BorderlessTableCell>
                      <Typography
                        variant="subtitle2"
                        className={typeClasses.driveBlue}
                        style={{
                          marginLeft: 36,
                          paddingTop: 24,
                          display: 'inline-block',
                        }}
                      >
                        {t('residual')} (%/$)
                      </Typography>
                    </BorderlessTableCell>
                    <BorderlessTableCell>
                      <Typography
                        variant="subtitle2"
                        className={typeClasses.driveBlue}
                        style={{
                          paddingTop: 24,
                        }}
                      >
                        {t('monthly-cost')}
                      </Typography>
                    </BorderlessTableCell>
                    <BorderlessTableCell
                      align="right"
                      className="profit"
                      style={{ background: '#DDEBF4' }}
                    >
                      &nbsp;
                    </BorderlessTableCell>
                  </TableRow>
                )}
                <TermRow
                  data={d}
                  title={d.term}
                  index={index}
                  selectedLease={selectedLease}
                  updateSelection={handleLeaseUpdate}
                  chosenOptions={chosenOptions}
                />
              </Fragment>
            ))}
          </TableBody>
        </Table>
      ))}
    </div>
  );
};

LeasingOptionsChart.propTypes = {
  leasingOptions: PropTypes.arrayOf(PropTypes.shape({})),
  updateSelection: PropTypes.func.isRequired,
  chosenOptions: PropTypes.arrayOf(PropTypes.shape({})),
  selectedLease: PropTypes.string,
  setSelectedLease: PropTypes.func,
};

LeasingOptionsChart.defaultProps = {
  leasingOptions: undefined,
  chosenOptions: [],
  selectedLease: '',
  setSelectedLease: () => {},
};

export default LeasingOptionsChart;
